<template>
<div>
  <v-dialog v-model="showDialog"
            max-width="900" :fullscreen="$vuetify.breakpoint.width < 600"
            content-class="dialog-large-bordered" scrollable
            v-if="entity != null">
    
      <UserPage v-if="entity.dataType == 'user'" :entity="entity" :inDialog="true"></UserPage>
      <CompanyPage v-if="entity.dataType == 'company'" 
                  :entityParent="entity" 
                  :inDialog="true"
                  :commandeEdit="commandeEdit">
      </CompanyPage>
    
  </v-dialog>
</div>
</template>


<script>
import UserPage from '@/components/entity/UserPage.vue'
import CompanyPage from '@/components/entity/CompanyPage.vue'

export default {
  name: 'home',
  props: [],
  components: {
    UserPage, CompanyPage
  },
  data: () => ({
    showDialog: false,
    entity: null,
    commandeEdit: null
  }),
  mounted: async function(){

    this.$root.$off('openDialogEntity').$on('openDialogEntity', (entity, commandeEdit=null) => { 
      this.entity = entity
      this.commandeEdit = commandeEdit
      this.showDialog = true
      //console.log("openDialogEntity", entity.productsIn, commandeEdit.basket)
      //this.amountProposal = entity.amountMony
          
      setTimeout(()=>{ 
          if(this.$refs.map != null)
          this.$refs.map.mapObject.invalidateSize();
          //this.showMap = false
        }, 500)
    })

    this.$root.$off('closeDialogEntity').$on('closeDialogEntity', () => { 
      this.showDialog = false
      setTimeout(()=>{ this.entity = null }, 1000)
    })
  },
  methods: {
    
  },
  
}
</script>
