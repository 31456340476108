<template>
<v-tabs icons-and-text
        class="toolbar-map-bootom" grow
        :style="'width:'+mapWidth+'px;'">
  <template v-for="(score, keyS) in $store.state.auth.user.scores">
    <v-tab :key="keyS" class="font-righteous" :title="'Score ' + score.name">
      <v-progress-linear class="mt-2" 
                        :value="score.score" 
                        :color="score.score > 70 ? 'green' : (score.score > 30) ? 'orange' : 'red'">
      </v-progress-linear>
      <small>
        <v-icon >mdi-{{ score.icon }}</v-icon> 
        {{ score.score.toFixed(0) }}%
      </small>
    </v-tab>
  </template>
</v-tabs>
</template>

<style></style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

//import Post from '@/components/Post.vue'

export default {
  name: 'score-bar',
  components: {  },
  data: () => ({
    mapWidth: 0,
    
  }),
  async mounted(){
    this.calcToolbarWidth()
  },
  methods: {
    calcToolbarWidth(){
      let el = document.getElementById("col-map")
      console.log("el", el)
      this.mapWidth = el == null ? 0 : el.offsetWidth
    },
  },
  computed: {
    
  },
  watch: {
    
      '$vuetify.breakpoint.width': { immediate: true, 
          async handler () {
              this.calcToolbarWidth()
          }
      },
    
  }
}
</script>
