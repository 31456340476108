<template> 
  <v-card v-if="entity != null" elevation="0" style="border-radius:0px;">

    <!-- TOOLBAR -->
    <v-toolbar color="" elevation="0" height="50" max-height="50">
      
      <template v-if="commandeEdit == null">
        <v-icon  :color="entity.category.iconColor" class="pr-1 py-0">
          mdi-{{ entity.category.icon }}
        </v-icon>    
        <b class="pr-2">{{ entity.ownModel.name }}</b>
      </template>
      <template v-else>
        <v-icon class="pr-1 py-0">
          mdi-pencil
        </v-icon>    
        <b class="pr-2">Modifier une commande</b>
      </template>

      <v-spacer/>

      <v-btn fab elevation="0" v-if="commandeEdit == null" x-small @click="updateDataEntity" 
            title="actualiser la quantité de stocks disponibles de chaque produit (il est possible que les stocks aient changés depuis le chargement des données)"
            class="mr-2">
        <v-icon small class="">mdi-refresh</v-icon>
      </v-btn>

      <v-btn x-small outlined fab class="ml-2"
             @click="$root.$emit('closeDialogEntity')" v-if="inDialog">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- SCROLLABLE -->
    <v-card-text class="px-0">
      <!-- HEADER -->
      <v-row class="mx-0 my-0" v-if="commandeEdit != null">
        <v-col cols="12" class="px-4">
          <v-icon  :color="entity.category.iconColor" class="pr-1 py-0">
            mdi-newspaper-variant-outline
          </v-icon>    
          <b class="pr-2">Commande n°{{ commandeEdit._id }}</b>
        </v-col>
      </v-row>
      
      <v-divider/>

      <v-row class="mx-0 my-0">
        <v-col cols="12" sm="12" md="12" class="py-0">
          
          <v-list class="py-0">
            <v-list-item class="px-0">
                <v-list-item-avatar height="46" width="46" :class="'mr-3'">
                  <v-gravatar height="30" style="border-radius:50%; margin-bottom:-6px;"
                              :default-img="'retro'" :email="entity.name"/>
                </v-list-item-avatar>
                
                <v-list-item-content>

                  <v-list-item-title class="headline mb-1" style="line-height: 1.3rem;">
                    <span style="text-transform:none;font-size:0.7em!important;text-decoration:none;white-space:normal;display:inline;" 
                       :class="'pa-0'">
                       <b>{{ entity.name }}</b>
                    </span>
                  </v-list-item-title>
                  
                  <v-list-item-subtitle v-show="entity.address!=null ||  entity.city!=null">
                    <v-icon small style="margin-bottom:3px;margin-right:-1px;" v-if="entity.city != ''">mdi-map-marker</v-icon>
                    {{ entity.address }} {{ entity.city }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle v-if="entity.category!=null">
                    <span :class="entity.category.iconFaColor+'--text'">{{ entity.category.name }}</span> - 
                    <b class="pr-2">{{ entity.ownModel.name }}</b>
                  </v-list-item-subtitle>

                  <v-list-item-subtitle class="mt-1" 
                        :title="'Surface totale : ' + nbParcelles + ' x ' + entity.surfaceParcelle+'m2 = ' + entity.surface+'m2'">
                    <v-icon small color="grey" style="margin-bottom:3px;margin-right:2px;">mdi-texture-box</v-icon> 
                    <b>x{{ nbParcelles }}</b>
                  </v-list-item-subtitle>

                </v-list-item-content>
                <v-list-item-content>
                  <div class="text-right">
                  <v-btn   fab color="blue lighten-1" class="mr-2 mt-2" title="contacter le propriétaire" 
                          :disabled="!$store.state.auth.isLogin || !$store.state.auth.user.isActive || $store.state.auth.user._id == entity.userOwner._id || !$store.state.auth.user.planPaid || !$store.getters['auth/accountReady']"
                          :to="'/private/'+entity.userOwner._id + '/' + entity._id"
                          @click="$root.$emit('closeDialogEntity')">
                    <v-icon  color="" class="">mdi-comment-account-outline</v-icon>
                  </v-btn>
                  </div>
                </v-list-item-content>
            </v-list-item>
          </v-list>
                    
          <!-- <v-card-text class="px-0 pt-0 pb-0 mb-4 card-text-entity" 
                       v-if="entity.description.length > 0"
                       v-html="nl2br(entity.description)">
          </v-card-text> -->
          
          <div class="flex orange--text mt-3" v-if="!$store.state.auth.isLogin">
            <v-btn  outlined to="/login" class="orange--text">
              <v-icon small>mdi-login</v-icon> Se connecter
            </v-btn><br>
            Connectez-vous pour interagir avec cette entité.
          </div>

        </v-col>

        <!-- <v-col cols="12" sm="12" class="pt-0">

          <v-btn  small color="blue lighten-1" class="mr-2 mt-2" outlined 
                  :disabled="!$store.state.auth.isLogin || !$store.state.auth.user.isActive || $store.state.auth.user._id == entity.userOwner._id || !$store.state.auth.user.planPaid || !$store.getters['auth/accountReady']"
                  :to="'/private/'+entity.userOwner._id + '/' + entity._id"
                  @click="$root.$emit('closeDialogEntity')">
            <v-icon small color="" class="pr-1">mdi-comment-account-outline</v-icon>
          </v-btn> 
          
        </v-col> -->

      </v-row>
      <v-divider/>

      <v-row class="ma-0" v-if="entity.dataType == 'company'">
        <v-col cols="12" class="px-2">
          <v-btn  v-for="i in 1, entity.employees.length" class="mx-1" 
                  x-small fab color="green" 
                  :key="i" outlined @click="$root.$emit('openDialogBecomeSalary', entity)">
            <v-icon style="font-size:22px!important;" class="mb-1">mdi-account-tie</v-icon>
          </v-btn>
          <v-btn  v-for="i in 1, nbEmployeeNeeded" class="mx-1" 
                  x-small fab color="teal" title="afficher l'offre d'emplois"
                  :key="i+'x'" outlined @click="$root.$emit('openDialogBecomeSalary', entity)">
            <v-icon style="font-size:22px!important;" class="mb-1">mdi-account-tie</v-icon>
          </v-btn>
          <v-btn v-if="nbEmployeeNeeded > 0" class="ma-1 teal--text" elevation="0" 
                @click="$root.$emit('openDialogBecomeSalary', entity)">
            {{ nbEmployeeNeeded }} poste{{ nbEmployeeNeeded>1?'s':'' }} à pourvoir
          </v-btn >
        </v-col>
      </v-row>
      <v-divider/>

      <!-- STOCK - PRODUITS DISPONIBLES A LA VENTE -->

      <!-- Vue différente en fonction de certains roles attribués à la company -->
      <template v-if="entity.role == 'builder'">
        <BasketBuilder :entity="entity"/>
      </template>
      <template v-else>
        <!-- vue par défaut -->
        <v-row class="mx-0 py-5 px-3" v-if="entity.dataType == 'company' && entity.productsOut.length > 0">

          <template v-if="entity.category.key != 'service'">
            <v-col cols="12" class="pt-0 pb-2 px-0">
              <v-icon  class="mb-1 mr-1">mdi-store</v-icon>
              <b>Produits disponibles à la vente</b>
              <small class="d-none d-sm-inline"> - cliquez pour ajouter au panier</small>
            </v-col>
            <template v-for="(product, key) in entity.productsOut">
                <v-chip outlined :key="key+'-out'"  
                      :color="product.iconColor"
                      :title="product.name"
                      class="ma-1 py-0 pl-2 pr-5 text-center"
                      style="height:56px;" large
                      @click="addToBasket(product)"
                      :disabled="product.stock < 1">
                  <v-icon :color="product.iconColor" class="mr-1">
                    mdi-{{ product.icon }}
                  </v-icon>
                  <span>
                    <b>
                      {{ product.name }} ·
                      {{ parseInt(product.stock) }} 
                      {{ product.priceUnity }}{{ product.stock >= 2 ? "s" : "" }}
                    </b>
                    <br>
                    {{ product.price }} òto/{{ product.priceUnity }}
                    <small>~{{ monyToEuro(product.price).toFixed(2) }} €</small>
                  </span>
                </v-chip>
            </template>
          </template>

          <template v-if="entity.category.key == 'service'">
            <v-col cols="12" class="pt-0 pb-2 px-0">
              <v-icon  class="mb-1 mr-1">mdi-store</v-icon>
              <b>Services proposés</b>
              <small class="d-none d-sm-inline"> - cliquez pour utiliser un service</small>
            </v-col>
            <template v-for="(product, key) in entity.productsOut">
                <v-btn outlined :key="key+'-out'"  
                      :color="product.iconColor"
                      :title="product.name"
                      class="ma-1 py-0 btn-service"
                      style="height:36px;" large
                      @click="addToBasket(product)"
                      :disabled="product.stock == 0">
                  <v-icon :color="product.iconColor" class="mr-1">
                    mdi-{{ product.icon }}
                  </v-icon>
                  <br>
                  {{ product.name }}
                  <br>
                  <span class="font-3x">{{ product.price }} òto</span>
                  <small class="pl-1">~{{ monyToEuro(product.price) }} €</small>
                  <!-- <template v-for="product.services" -->
                </v-btn>
            </template>
          </template>

          <!-- PANIER + FACTURATION -->
          <v-col cols="12" class="pt-0 pb-2 px-0 mt-3" v-if="basket.length == 0">
            <v-card outlined elevation="0">
              <v-card-title class="pb-2">
                <v-icon class="mb-1 mr-2">mdi-basket</v-icon>
                <b>Votre panier est vide</b>
              </v-card-title>
              <v-divider/>
              <v-card-subtitle>
                Sélectionnez des produits pour remplir votre panier...
              </v-card-subtitle>
            </v-card>
          </v-col>

          <v-col cols="12" class="pt-0 pb-2 px-0 mt-3" v-else>
            <v-card outlined elevation="0">
              <v-row class="pa-3">

                <v-col cols="12" class="">
                  <v-icon class="mb-1 mr-2">mdi-basket</v-icon>
                  <b>Panier</b>
                </v-col>
                <!-- SLIDERS CONFIG QUANTITY -->
                <v-col cols="12" class="px-0 py-0">
                  <v-divider class="mb-3"/>
                  <small class="d-none d-sm-inline"></small>
                
                  <template v-for="(product, key) in basket">
                    <!-- DETAILS QUANTITY -->
                    <v-toolbar height="30" :key="key+'-tool'" elevation="0" color="transparent" class="toolbar-basket mt-3">
                        <v-btn x-small elevation="0" class="mr-1" 
                              title="supprimer le produit du panier"
                              @click="cancelProductBasket(product)">
                            <span class="d-none d-md-inline">annuler</span>
                            <v-icon x-small class="d-md-none">mdi-cancel</v-icon>
                          </v-btn>
                          <v-icon :color="product.iconColor" class="mr-1">
                            mdi-{{ product.icon }}
                          </v-icon>
                        <span outlined 
                              :class="product.iconColor+'--text'"
                              :title="'prix ' + product.name"
                              class=""
                              style="height:26px;line-height:17px;">
                          
                          <b>{{ product.name }}</b><br>
                          <small>
                            {{ product.price > 1 ? product.price : product.price.toFixed(4) }} 
                            òto/{{ product.priceUnity }}
                          </small>
                        </span>
                        <v-spacer/>
                        <b :class="product.iconColor+'--tex'" v-if="entity.category.key != 'service'"
                          :title="'acheter ' + (product.stock > 1 ? product.stock : product.stock.toFixed(2)) + ' ' + product.priceUnity + ' de ' + product.name">
                        {{ product.stock > 1 ? product.stock : product.stock.toFixed(2) }} 
                        {{ product.priceUnity }}{{ product.stock >= 2 ? "s" : "" }}
                        </b>
                        <v-spacer/>
                        <span class="ml-2" :title="'~ '+monyToEuro(product.stock * product.price).toFixed(2) + ' €'">
                          {{ (product.stock * product.price).toFixed(2) }} òto
                        </span>
                    </v-toolbar>
                    <!-- SLIDER QUANTITY -->
                    <v-slider v-model="product.stock" :min="0" :max="parseInt(product.stockDispo)" :key="key+'-slider'"
                              class="mx-3" :color="product.iconColor" :step="product.stockDispo < 1 ? 0.1 : 1"
                              hide-details v-show="entity.category.key != 'service'">
                              <template v-slot:prepend>
                                <v-icon @click="decrement(product)">mdi-minus</v-icon>
                              </template>

                              <template v-slot:append>
                                <v-icon @click="increment(product)">mdi-plus</v-icon>
                              </template>
                    </v-slider>
                    <v-divider class="my-3 mb-1" :key="key+'-divider'"/>
                  </template>
                  
                </v-col>

                <!-- TABLEAU FACTURATION -->
                <v-col cols="12" class="pt-0 px-0 text-right">
                  <template v-if="entity.fret!='none'">
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td><b>Total panier</b></td>
                            <td>
                              <span class="d-none d-sm-inline-flex" v-if="entity.category.key != 'service'">
                              {{ basket.length }} produit{{ basket.length > 1 ? 's' : '' }}
                              </span>
                              <span class="d-none d-sm-inline-flex" v-else>
                              {{ basket.length }} service{{ basket.length > 1 ? 's' : '' }}
                              </span>
                            </td>
                            <td>{{ monyToEuro(amountTotalBasket).toFixed(2) }} €</td>
                            <td>{{ (amountTotalBasket).toFixed(4) }} òto</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                    <!-- POID DU PANIER -->
                    <v-col cols="12" class="pt-0 px-0 text-center blue--text">
                  
                      <v-divider class="my-2"/>
                      <v-chip outlined class="ml-3 my-2" color="blue" v-if="entity.category.key != 'service'">
                        <v-icon class="mr-1" small>mdi-weight</v-icon> 
                        Poids du panier : {{ basketWeight }} kg
                      </v-chip>
                      <v-chip outlined class="ml-3 my-2" color="blue">
                        <v-icon class="mr-1" small>mdi-road-variant</v-icon> 
                        Distance : {{ distance }} km
                      </v-chip>
                      <br><br>
                      <v-icon color="blue" class="ml-5 pl-3">mdi-chevron-down</v-icon>
                      <b v-if="entity.category.key != 'service'">Mode de livraison</b>
                      <b v-else>Mode de transport</b>
                      <v-btn x-small fab elevation="0" class="ml-2">
                        <v-icon  color="blue">mdi-information-outline</v-icon>
                      </v-btn>

                    </v-col>

                    <!-- TOOLBAR CHOIX DU TRANSPORTEUR -->
                    <v-toolbar class="tab-transporters" height="55" 
                              elevation="0" outlined style="border-width:1px 0; margin-bottom:-1px;" dense>
                      <v-spacer/>
                      <v-btn @click="deliveryMode = 'myself'"
                              :disabled="!canDeliveryHimself"
                              :outlined="deliveryMode != 'myself'"
                              color="blue"
                              class="ma-1 mb-2" elevation="0"
                              >
                        <v-icon small>mdi-walk</v-icon>
                        Je me déplace
                      </v-btn>
                      <v-btn @click="deliveryMode = 'delivery'"
                              title=""
                              :outlined="deliveryMode != 'delivery'"
                              color="blue"
                              class="ma-1 mb-2" elevation="0"
                              >
                        <v-icon small v-if="entity.category.key != 'service'">mdi-cart-arrow-right</v-icon>
                        <v-icon small v-else>mdi-arrow-right</v-icon>
                        
                        <span v-if="entity.category.key != 'service'">Je me fais livrer</span>
                        <span v-else>Je me fais transporter</span>
                      </v-btn>
                      <v-spacer/>
                    </v-toolbar>

                    <v-toolbar class="tab-transporters mb-5" height="55" 
                              elevation="0" outlined style="border-width:1px 0" dense>
                      <v-spacer/>
                      <template v-for="(transport, keyT) in $store.state.map.transporters[deliveryMode]">
                        <v-btn :key="keyT" @click="transporter = keyT"
                              :disabled="transport.maxWeight < basketWeight || transport.maxDistance < distance"
                              :title="'Peut transporter jusqu\'à ' + transport.maxWeight + ' kg, à la vitesse de '+ transport.speedKm + ' km/h'"
                              :outlined="transporter == keyT"
                              :color="transporter == keyT ? 'blue' : ''"
                              class="ma-1 mb-2" elevation="0"
                              fab small>
                          <v-icon :color="transporter == keyT ? '' : 'blue lighten-2'">mdi-{{ transport.icon }}</v-icon>
                        </v-btn>
                      </template>
                      <v-spacer/>
                    </v-toolbar>
                  </template>

                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-right">Intitulé</th>
                          <th class="text-right">
                            <span class="d-none d-sm-inline-flex">
                              Détails
                            </span>
                          </th>
                          <th class="text-right">prix en €</th>
                          <th class="text-right">prix en òto</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="entity.fret!='none'">
                          <td>Cout du trajet</td>
                          <td v-if="$store.state.map.transporters[deliveryMode][transporter]">
                            <small class="d-none d-sm-inline-flex">
                            {{ getDistance($store.state.auth.user.coordinates, entity.coordinates) }} km X 
                            {{ $store.state.map.transporters[deliveryMode][transporter].priceKm }} òto
                            </small>
                          </td>
                          <td>{{ monyToEuro(distanceCost).toFixed(2) }} €</td>
                          <td>{{ distanceCost.toFixed(4) }} òto</td>
                        </tr>
                        <tr v-if="entity.fret!='none'">
                          <td>Total panier</td>
                          <td>
                            <span class="d-none d-sm-inline-flex">
                            {{ basket.length }} produit{{ basket.length > 1 ? 's' : '' }}
                            </span>
                          </td>
                          <td>{{ monyToEuro(amountTotalBasket).toFixed(2) }} €</td>
                          <td>{{ (amountTotalBasket).toFixed(4) }} òto</td>
                        </tr>
                        <tr>
                          <td style="font-size:1.2em!important;"><b>Total commande</b></td>
                          <td style="font-size:1.2em!important;"><span class="d-none d-sm-inline-flex">-</span></td>
                          <td style="font-size:1.2em!important;">{{ monyToEuro(amountTotalBasket + distanceCost).toFixed(2) }} €</td>
                          <td style="font-size:1.2em!important;"><b>{{ (amountTotalBasket + distanceCost).toFixed(4) }} òto</b></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <!-- FACTURER A -->
                  <v-col cols="12" class="pt-0 px-0 text-center blue--text" v-show="!commandeEdit && entity.category.key != 'service'">
                
                    <v-divider class="mt-1 mb-4"/>
                    <v-icon color="blue" class="ml-5 pl-3">mdi-chevron-down</v-icon>
                    <b>Facturer et livrer à</b>
                    <v-btn x-small fab elevation="0" class="ml-2">
                      <v-icon color="blue">mdi-information-outline</v-icon>
                    </v-btn>
                    <v-select v-model="sendTo"
                            :items="sendToEntities"
                            outlined class="mx-3 mt-3"
                            item-value="_id"
                            item-text="name"
                            label="Facturer et livrer à...">
                    </v-select>
                    <v-divider class="my-2"/>
                  </v-col>
                  <v-col cols="12" class="pt-0 px-0 text-center blue--text" v-if="commandeEdit && entity.category.key != 'service'">
                    <v-divider class="mt-1 mb-4"/>
                    <v-icon color="blue" class="">mdi-chevron-down</v-icon>
                    <b class="pr-2">Facturer et livrer à</b><br>
                    <v-chip color="" class="pr-4" outlined large>
                      <v-gravatar height="35" style="border-radius:50%;margin-right:8px;"
                                :default-img="'retro'" :email="commandeEntityBuyer.name"/>
                      {{ commandeEntityBuyer.name }}
                    </v-chip>
                    <br><br>
                    <v-divider/>
                  </v-col>
                  <v-col cols="12" class="pt-0 px-0 text-center blue--text" v-if="entity.category.key == 'service'">
                    <v-divider class="mt-1 mb-4"/>
                    <v-icon color="blue" class="">mdi-chevron-down</v-icon>
                    <b class="pr-2">Facturer à</b><br>
                    <v-chip color="" class="pr-4" outlined large>
                      <v-gravatar height="35" style="border-radius:50%;margin-right:8px;"
                                :default-img="'retro'" :email="$store.state.auth.user.name"/>
                      {{ $store.state.auth.user.name }}
                    </v-chip>
                    <br><br>
                    <v-divider/>
                  </v-col>
                  
                  <v-col cols="12" class="py-0 mb-0 text-left" v-show="recurrent">
                    Renouveler tous les 
                    <v-chip color="indigo" small class="ml-1 mb-1">
                      {{ recurrenceDays }} jour{{ recurrenceDays > 1 ? 's' : '' }}
                    </v-chip>
                    <v-slider v-model="recurrenceDays" hide-details 
                              min="1" max="7" color="indigo">
                    </v-slider>
                    <v-card-subtitle class="pa-0 mb-1">
                      Cette commande sera automatiquement renouvelée tous les
                      {{ (recurrenceDays > 1 ? recurrenceDays : '') }} jours.<br>
                      Vous pourrez l'annuler et la modifier à tout moment.
                    </v-card-subtitle>
                  </v-col>

                  <v-toolbar elevation="0" color="transparent" class="d-sm-none" v-if="entity.role != 'builder'">
                    <v-spacer/>
                    <v-checkbox color="indigo" label="Commande récurente" v-model="recurrent"></v-checkbox>
                  </v-toolbar>

                  <!-- TOOLBAR FINAL BTN ACHETER ANNULER -->
                  <v-toolbar elevation="0" color="transparent" class="tab-transporters ma-0" dense>
                    <v-checkbox label="Commande récurente" v-model="recurrent" v-if="entity.role != 'builder'"
                                class="mt-5 d-none d-sm-inline"></v-checkbox>
                    <v-spacer/>
                    <template v-if="commandeEdit == null">
                      <v-btn color="red" outlined class="mr-1">
                        <v-icon small class="px-0">mdi-cancel</v-icon> Annuler
                      </v-btn>
                      <v-btn color="green" @click="sendCommande" :disabled="amountTotalBasket <= 0 || sendTo == null" class="mr-3 ml-2">
                        <v-icon small class="mr-1">mdi-basket-check</v-icon> Valider la commande
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn color="red" outlined class="mr-1" title="annuler cette commande (supprimer)">
                        <v-icon small class="px-0">mdi-cancel</v-icon> 
                        Annuler <span class="d-none d-sm-inline" style="margin:0 1px;"> la</span> commande
                      </v-btn>
                      <v-btn color="green" @click="sendCommande" :disabled="amountTotalBasket <= 0 || sendTo == null" class="mr-3 ml-2">
                        <v-icon small class="mr-1">mdi-basket-check</v-icon> 
                        Valider <span class="d-none d-sm-inline" style="margin:0 1px;"> les</span> changements
                      </v-btn>
                    </template>
                  </v-toolbar>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

        </v-row>
      </template>
    </v-card-text>


  </v-card>
</template>

<style>
  @import "~leaflet/dist/leaflet.css";
  .toolbar-basket .v-toolbar__content{
    padding-left:7px;
  }
  @media (max-width: 600px) {
    .tab-transporters .v-tab{
      min-width: unset;
    }
    .tab-transporters .v-toolbar__content{
      padding: 0px;
    }
    .tab-transporters .v-btn{
      padding: 0 10px !important;
      min-width: unset !important;
    }
  }

  .btn-service{
    height: unset !important;
  }
  .btn-service .v-btn__content{
    display: inline!important;
    padding: 10px !important;
  }
</style>


<script>

import axios from "axios"

// import { i18n } from 'vue-lang-router';
import core from '../../plugins/core.js'
const config = require('../../config/' + process.env.NODE_ENV)

import L from 'leaflet';
import Gravatar from 'vue-gravatar'

import BasketBuilder from '@/components/entity/basket/BasketBuilder.vue'


export default {
  name: 'home',
  props: ['entityParent', 'inDialog', 'commandeEdit'],
  components: {
    'v-gravatar' : Gravatar, BasketBuilder
  },
  data: () => ({
    entity: null,
    basket: [],
    transporter: 'feet',
    recurrent: false,
    recurrenceDays: 1,
    deliveryMode: 'myself',
    sendTo: null,

  }),
  mounted: async function(){
    this.entity = this.entityParent
    
    this.$root.$off('updateCompanyPage')
              .$on('updateCompanyPage', () => { 
      this.updateDataEntity()
    })

    this.init()
  },
  methods: {
    init(){
      this.sendTo = this.entity.canSaleToUser!=false ? this.$store.state.auth.user._id : null 
      if(this.sendTo == null && this.$store.state.auth.user.companies.length > 0)
        this.sendTo = this.$store.state.auth.user.companies[0]._id

        console.log("init !!!", this.entity.canSaleToUser, this.$store.state.auth.user._id, this.sendTo)
    },
    monyToEuro(amount){ return amount * config.monyToEuro },
    nl2br: function(str, is_xhtml){
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
      let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
      //let maxLength = 150
      // let p = newStr.length > maxLength ? '...' : ''
      // newStr = newStr.substr(0, maxLength) + p
      return newStr
    },
    baseUrl(){ return core.baseUrl() },
    updateDataEntity(){
      if(this.commandeEdit == null)
      axios.post('/map/search', { id: this.entity._id })
          .then((res) => {
            if(!res.data.error) {
              this.entity = res.data.results.companies.entities[0]
              this.basket = []
            }
            else console.log("error fetching entity", res.data)
          })

    },
    sendCommande(){
      let params = {
          basket: this.basketIds,
          transporterType: this.transporter,
          recurrent: this.recurrent,
          recurrenceDays: this.recurrenceDays,
          deliveryMode: this.deliveryMode,
          buyerId: this.sendTo,
          sellerCompanyId: this.entity._id,
      }
      if(params.basket.length == 0
      || params.buyerId == null){
        console.log("params error", params)
        return 
      }

      if(this.commandeEdit != null) 
        params.commandeEditId = this.commandeEdit._id

      axios.post('/game/save-commande', params)
        .then((res) => {
          console.log("ok", res.data)
          this.$store.dispatch('auth/refreshUserData')
          this.$root.$emit("closeDialogEntity")
        })
        .catch((e)=>{
          console.log("error", e)
        })
    },
    addToBasket(product){
      let prodBK = JSON.parse(JSON.stringify(product))
      console.log("prodBK", prodBK)
      let stock = prodBK.stock >= 1 ? 1 : prodBK.stock
      prodBK.stock = stock
      prodBK.stockDispo = product.stock
      this.basket.push(prodBK)
    },
    cancelProductBasket(product){
      let i = -1
      this.basket.forEach((prod, key) => {
        if(prod._id == product._id) i = key
      })
      if(i > -1) this.basket.splice(i, 1)
    },
    decrement: function(prodBK){
      let step = prodBK.stockDispo < 1 ? 0.1 : 1
      if(prodBK.stock - step <= 0) return
      prodBK.stock -= step
    },
    increment: function(prodBK){
      let step = prodBK.stockDispo < 1 ? 0.1 : 1
      if(prodBK.stock >= prodBK.stockDispo) return
      prodBK.stock += step
    },
    getDistance(origin, destination){
      return core.getDistance(origin, destination)
    },
    autoSelectTransporter(){
      if(!this.canDeliveryHimself) this.deliveryMode = "delivery"
      this.transporter = this.$store.state.map.transportersKeys[this.deliveryMode][0]
      let distance = this.distance
      if(this.$store.state.map.transportersKeys[this.deliveryMode] != null)
      this.$store.state.map.transportersKeys[this.deliveryMode].forEach((keyT)=>{
        //console.log("transporteur actuel", this.$store.state.map.transporters[this.deliveryMode][this.transporter].name)
        //console.log("distance", distance, "basketWeight", this.basketWeight)
        if(this.$store.state.map.transporters[this.deliveryMode][this.transporter].maxDistance < distance
        || this.$store.state.map.transporters[this.deliveryMode][this.transporter].maxWeight < this.basketWeight){
          let transporter = this.$store.state.map.transporters[this.deliveryMode][keyT] 
          //console.log("recherche nouveau transporteur", transporter.name, transporter.maxDistance, ">", distance)
          if(transporter.maxDistance > distance
          && transporter.maxWeight > this.basketWeight) {
            this.transporter = keyT
            //console.log("transporteur changé", this.transporter)
          }
        }
      })
    }
  },
  computed: {
    marker(){ 
      let color = "orange"
      return L.icon({
        iconUrl: '/marker/marker-'+color+'.png',
        shadowUrl: '/marker/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
    },
    amountTotalBasket(){
      let total = 0
      this.basket.forEach(product => {
        total += product.price * product.stock 
      })
      return total
    },
    distance(){
      return this.getDistance(this.$store.state.auth.user.coordinates, this.entity.coordinates)
    },
    distanceCost(){
      if(this.$store.state.map.transporters[this.deliveryMode][this.transporter] == null) return 0
      let distCost = this.$store.state.map.transporters[this.deliveryMode][this.transporter].priceKm
      return this.distance * distCost
    },
    basketWeight(){
      let totalWeight = 0
      this.basket.forEach((product)=>{
        totalWeight += product.weightByUnity * product.stock
      })
      return totalWeight
    },
    canDeliveryHimself(){
      let can = false
      this.$store.state.map.transportersKeys['myself'].forEach((transKey)=>{
        let transporter = this.$store.state.map.transporters['myself'][transKey]
        if(transporter.maxDistance > this.distance
        && transporter.maxWeight > this.basketWeight)
          can = true
      })
      console.log("canDeliveryHimself", can)
      return can
    },
    basketIds(){
      let ids = []
      this.basket.forEach((prod) => {
        ids.push({
          //productId: prod._id,
          key: prod.key,
          name: prod.name,
          quantity: prod.stock,
          price: prod.price,
          priceUnity: prod.priceUnity,
          weightByUnity: prod.weightByUnity,
          ownModel: prod.ownModel,
          icon: prod.icon,
          iconColor: prod.iconColor,
        })
      })
      return ids
    },
    nbParcelles(){
      return this.entity.surface / this.entity.surfaceParcelle
    }, 
    nbEmployeeNeeded(){
      //console.log("nbEmployeeNeeded",  this.entity.surface, this.entity.surfaceParcelle, this.entity.nbEmployeesByParcelle)
      //-1 pour le patron qui compte comme un employé
      return (this.entity.surface / this.entity.surfaceParcelle * this.entity.nbEmployeesByParcelle) - this.entity.employees.length - 1
    },
    sendToEntities(){
      let all = []
      let me = [{ _id: this.$store.state.auth.user._id, name: "Moi - " + this.$store.state.auth.user.name }]
      if(this.entity.canSaleToUser) all = me
      
      if(this.entity.canSaleToCompany)
        all = all.concat(this.$store.state.auth.user.companies)
        
      return all
    },
    commandeEntityBuyer(){
      console.log('this.commandeEdit', this.commandeEdit)
      if(this.commandeEdit == null) return null
      if(this.commandeEdit.buyerCompany != null) return this.commandeEdit.buyerCompany
      else return this.commandeEdit.buyerUser
    },

  },
  
  watch: {
      'entityParent': { immediate: true, 
          async handler () {
            //console.log("alo", this.entityParent, this.entityParent == null)
            if(this.entityParent == null) return
            this.entity = this.entityParent
            this.basket = []
            this.sendTo = null
            this.init()
          }
      },
      

      'basketWeight': { immediate: true, 
          async handler () {
            this.autoSelectTransporter()
          }
      },
      'deliveryMode': { immediate: true, 
          async handler () {
            this.autoSelectTransporter()
          }
      },
      'commandeEdit': { immediate: true, 
          async handler () {
            if(this.commandeEdit == null) return
            //let newBasket = []
            this.basket         = []
            this.commandeEdit.basket.forEach((productB)=>{
              this.entity.productsOut.forEach((product)=>{
                let prodBK = JSON.parse(JSON.stringify(product))
                console.log("prodBK", prodBK)
                if(prodBK.key == productB.key){
                  //prodBK.stock = productB.quantity
                  //let stock = prodBK.stock >= 1 ? productB.quantity : prodBK.stock
                  prodBK.stock = productB.quantity
                  prodBK.stockDispo = product.stock
                  this.basket.push(prodBK)
                }
              })
            })
            //this.basket         = this.commandeEdit.basket
            this.deliveryMode   = this.commandeEdit.deliveryMode
            this.transporter    = this.commandeEdit.transporter
            this.recurrent      = this.commandeEdit.recurrent
            this.recurrenceDays = this.commandeEdit.recurrenceDays
            this.sendTo         = this.commandeEntityBuyer._id
          }
      },
  
      
  }, 
}
</script>
