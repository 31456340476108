<template>
  <div v-if="entity != null && inMapFilterType" class="entity-card">
    <v-card @click="showDialogEntity()" elevation="0" class="entity-card-main-ctn" color="transparent" :class="entity.fictif ? 'border-fictif' : ''">
      <v-toolbar color="transparent" elevation="0" dense height="30">
        <small class="mr-1">
          <v-icon v-if="entity.dataType=='user'"    small color="red" class="pl-2 pb-1">mdi-circle</v-icon> 
          <v-icon v-if="entity.dataType=='company'" small :color="entity.iconColor" class="pl-2 pb-1">mdi-circle</v-icon> 
          <v-gravatar height="18" style="border-radius:50%; margin-bottom:-4px; margin:0 6px -4px 5px;"
                            :default-img="'retro'" :email="entity.name"/>
          <b class="">{{ entity.name }}</b> {{ entity.icon }}
          <v-icon x-small color="grey" v-if="entity.city" class="pl-2 pb-1">mdi-map-marker</v-icon> 
          {{ entity.city }}
        </small> 
        {{ services.length > 0 ? "·" : "" }}
        <template v-for="service in services">
          <v-icon x-small :color="service.scoreBonus >= 0 ? 'green' : 'red'" class="pl-1 mb-1" :key="entity._id + service.scoreModel.icon">
            mdi-{{ service.scoreModel.icon }}
          </v-icon>
        </template>
        <v-spacer/>
        <v-btn x-small text color="secondary" elevation="0" @click="showDialogEntity()">
          <v-icon small>mdi-arrow-right</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
  </div>
</template>

<style>
  .card-text-entity{
    line-height: 1.5;
    font-size:15px;
  }
  .entity-card .v-toolbar__content{
    padding-left:0px !important;
    padding-right:5px !important;
  }
  .entity-card .v-toolbar__content .v-btn{
    font-weight:300!important;
  }
  .col-entity-clickable{
    cursor:pointer;
  }
  .entity-card-main-ctn{
    border: transparent 0px solid;
    border-bottom: #5e5e5e25 1px solid !important;
    border-radius:0px!important;
    /* margin-bottom:2px; */
  }
  .entity-card-main-ctn .v-card.v-sheet{
    border: transparent 0px solid !important;
  }
  .entity-card-main-ctn .v-toolbar__content:hover{
    /* border: #dbdbdb 1px solid; */
    background-color: #5e5e5e25 !important;
    
  }
  /* .theme--dark .entity-card-main-ctn:hover{
    border: #222222 1px solid;
    background: #222222;
  } */
</style>

<script>

// import axios from "axios"
// import Rules from '@/components/home/Rules.vue'

// import { i18n } from 'vue-lang-router';
import core from '../../plugins/core.js'

import Gravatar from 'vue-gravatar'

const config = require('../../config/' + process.env.NODE_ENV)

export default {
  name: 'EntityCard',
  components: {
    'v-gravatar' : Gravatar,
  },
  props: [ 'entity', 'staticInline' ],
  data: () => ({
  }),
  mounted: async function(){
    
  },
  methods: {
    nl2br: function(str, is_xhtml){
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
      let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
      let maxLength = 150
      let p = newStr.length > maxLength ? '...' : ''
      newStr = newStr.substr(0, maxLength) + p
      return newStr
    },
    monyToEuro(amount){ return amount * config.monyToEuro },
    baseUrl(){ return core.baseUrl() },
    showDialogEntity() { 
      this.$root.$emit("showEntityOnMap", this.entity)
      this.$root.$emit('closeDialogResMap')
      //this.$root.$emit("openDialogEntity", this.entity)
    },
  },
  computed: {
    inMapFilterType(){
      let checkDataType = 
              this.$store.state.map.entityTypeSelected.length == 0
          || (this.entity.dataType == "user"    && this.$store.state.map.entityTypeSelected.indexOf('user') > -1) 
          || (this.entity.dataType == "company" && this.$store.state.map.entityTypeSelected.indexOf(this.entity.category._id) > -1)

      let search = this.$store.state.map.requestParams.search.toLowerCase()
      let name =  this.entity.name.toLowerCase()
      let match = name.indexOf(search)

      let checkSearchTxt = search.length > 0 ? (match > -1) : true

      return checkDataType && checkSearchTxt
    },
    services(){
      let servicesIcons = []
      let services = []
      if(this.entity.productsOut == null) return services
      
      this.entity.productsOut.forEach(product => {
        product.services.forEach(service => {
          let icon = service.scoreModel.icon
          if(servicesIcons.indexOf(icon) == -1){
            servicesIcons.push(icon)
            services.push(service)
          }
        })
      })
      return services
    }
  },
}
</script>
