<template>
<div>
  <SearchBlock></SearchBlock>
      
  <v-col cols="12" class="mb-2 text-center">    
    {{ $store.state.map.entities.length }}/{{ $store.state.map.countResultsTotal }}
    résultat{{ $store.state.map.entities.length > 1 ? 's' : '' }}
    trouvé{{ $store.state.map.entities.length > 1 ? 's' : '' }}
  </v-col>

  <v-row>
    <div class="item-scoller px-0"
        :style="'height:'+($vuetify.breakpoint.height-204)+'px'">
      <template  v-for="item in $store.state.map.entities">
        <ListItemEntity :entity="item" :key="item._id"></ListItemEntity>
      </template>

      <v-divider/>
        
      <div class="text-center pa-3" v-if="!$store.state.map.isEndOfStream && $store.state.map.entities.length > 0">
        <v-btn @click="$store.dispatch('map/fetchNextMap')" color="secondary"  outlined block elevation="0" class="mt-1 mb-2">
          <v-icon>mdi-progress-download</v-icon>
          Charger + de résultats
        </v-btn>
      </div>

      <div class="text-center py-3" v-if="$store.state.map.isEndOfStream && $store.state.map.entities.length > 0">
        <b>- Fin des résultats -</b><br>
        Modifiez votre recherche pour plus de résultats
        <br>
      </div>
    </div>
  </v-row>
</div>
</template>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

//import Post from '@/components/Post.vue'
import SearchBlock from '@/components/map/SearchBlock.vue'
import ListItemEntity from '@/components/map/ListItemEntity.vue'

export default {
  name: 'empty',
  components: { SearchBlock, ListItemEntity },
  data: () => ({
    
    
  }),
  async mounted(){
    
  },
  methods: {
    

  },
  computed: {
    
    
  }
}
</script>

