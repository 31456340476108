<template>
<v-row class="mx-0 py-5 px-0">
  <v-card-text>
    <i>
    Bonjour !<br>
    Vous souhaitez faire construire votre propre maison ou appartement ?<br>
    Parcourez notre catalogue et dites-nous ce qui vous intéresse !<br>
    </i>
  </v-card-text>

  <template v-for="(house, key) in entity.productsOut">
    <v-col cols="12" sm="6" md="4" class="" :key="key">
      <v-card outlined class="pb-3">
        <v-card-subtitle class="py-3 text-center" style="width:100%;">
          <v-icon x-large :color="house.iconColor">mdi-{{ house.icon }}</v-icon><br>
          <span class="font-main" :class="house.iconColor+'--text'">{{ house.name }}</span>
        </v-card-subtitle>
        <v-divider/>
        <v-simple-table style="width:100%">
          <tbody>
            <template v-for="production in entity.productions">
              <template v-for="productIn in production.productsIn">
                <tr v-if="production.productOut.key == house.key" :key="productIn._id">
                  <td :class="productIn.iconColor+'--text'" class="px-2">
                        <v-icon :color="productIn.iconColor" class="mr-1" >mdi-{{ productIn.icon }}</v-icon>
                        {{ (production.stockIn[productIn.key] * sizeCoeff).toFixed(2) }} 
                        {{ productIn.priceUnity.toLowerCase() != productIn.name.toLowerCase() ? productIn.priceUnity : '' }} {{ productIn.name }}
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </v-simple-table>
        <v-divider/>
        <v-simple-table style="width:100%" dense>
          <tbody>
            <tr :title="'~' + parseInt(monyToEuro(house.price/30)) + ' €'">
              <td class="text-left">Prix /m2</td>
              <td class="text-right">
                {{ (house.price/30).toFixed(2) }} òto
              </td>
            </tr>
            <tr :title="'~' + parseInt(monyToEuro((house.price/30) * sizeHouse)) + ' €'">
              <td style="border-bottom:0px;" class="text-left">Prix pour {{sizeHouse }} m2</td>
              <td style="border-bottom:0px;" class="text-right">
                {{ ((house.price/30) * sizeHouse).toFixed(2) }} òto
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-col cols="12" class="py-0 mt-2">
          <v-btn @click="selectHouse(house)" elevation="0" block>Choisir</v-btn>
        </v-col>

      </v-card>
    </v-col>
  </template>

  <v-col cols="12" class="pt-0 pb-2">
    <b class="py-3 mb-2">
      <v-icon class="mb-1 mr-1">mdi-chevron-down</v-icon>
      Indiquez la surface de votre futur logement
    </b>
    <div class="mt-1">
      <v-icon color="blue" class="mb-1 mr-1">mdi-texture-box</v-icon>
      <v-chip color="blue" class="ml-2" outlined><b>Surface du logement : {{ sizeHouse }} m2</b></v-chip>
      <v-slider min="30" max="300" v-model="sizeHouse"></v-slider>
    </div>
  </v-col>

  <v-col cols="12" class="py-0 px-0 mt-2" v-if="houseSelected">
    <v-divider/>
    
    <v-simple-table style="width:100%" >
      <tbody>
        <tr :title="'~' + parseInt(monyToEuro((houseSelected.price/30) * sizeHouse)) + ' €'">
          <td class="text-left">
            <v-card-title class="font-main pl-0" :class="houseSelected.iconColor+'--text'">
              <v-icon large class="mb-3" :color="houseSelected.iconColor">mdi-{{ houseSelected.icon }}</v-icon>
              {{ houseSelected.name }}<span class="d-none d-sm-inline"> de {{ sizeHouse }} m2</span>
            </v-card-title>
          </td>
          <td class="text-right font-2x">
            {{ totalPriceHouse }} òto
          </td>
        </tr>
        <tr :title="'~' + parseInt(monyToEuro($store.getters['auth/amountTotalCurrent'])) + ' €'">
          <td class="text-left pt-5">
            <v-icon class="mb-1 mr-1" >mdi-wallet</v-icon>
            <b class="font-main">Apport personnel · max {{ $store.getters['auth/totalDepositMony'].toFixed(2) + ' òto'}}</b>
            <v-slider v-model="percentReserve" min="1" max="100" :label="percentReserve+'%'"/>
          </td>
          <td class="text-right">
            {{ apportPerso }} òto
          </td>
        </tr>
        <tr :title="'~' + parseInt(monyToEuro(financeNeed)) + ' €'">
          <td class="text-left">
            <v-icon class="mb-1 mr-1" >mdi-account-cash-outline</v-icon>
            <b class="font-main">Besoin de financement</b>
          </td>
          <td class="text-right">
            {{ financeNeed.toFixed(2) }} òto
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    
    <v-toolbar>
      <v-spacer/>
      <v-btn @click="$root.$emit('openDialogLoan', $store.state.auth.user)" color="purple">
        Demander un financement
      </v-btn>
    </v-toolbar>
    
  </v-col>

</v-row>
</template>

<style></style>

<script>

import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

//import Post from '@/components/Post.vue'
const config = require('../../../config/' + process.env.NODE_ENV)

export default {
  name: 'BasketBuilder',
  props: ['entity'],
  components: {  },
  data: () => ({
    sizeHouse: 30,
    houseSelected: null,
    loanMaxDuration: 120,
    percentReserve: 50,
  }),
  async mounted(){
    
  },
  methods: {
    monyToEuro(amount){ return amount * config.monyToEuro },
    selectHouse(house){
      this.houseSelected = house
    },
    save(){
      let params = {
        companyId: this.company._id,
        houseId: this.houseSelected._id,
        houseSize: this.sizeHouse,

      }
      axios.post("/game/save-commande-housing", params)
           .then(res =>{
             if(res.data.error == false){
               console.log("ok", res.data)
             }
           })
    }
  },
  computed: {
    sizeCoeff(){
      return (this.sizeHouse / 30).toFixed(2)
    },
    totalPriceHouse(){
      return (this.houseSelected.price * this.sizeCoeff).toFixed(2)
    },
    financeNeed(){
      return (((this.houseSelected.price/30) * this.sizeHouse) - this.apportPerso)
    },
    apportPerso(){
      return (this.$store.getters['auth/totalDepositMony']*this.percentReserve/100).toFixed(2)
    }
  }
}
</script>
