<template>
<v-col id="map">
  
  <v-row>


    <v-col cols="12" sm="6" md="4" lg="3" class="d-none d-md-inline ma-0 col-search">
      <ListResMap></ListResMap>
    </v-col>

    <v-col cols="12" sm="12" md="8" lg="9" id="col-map" class="mr-auto pa-0">
      <MainMap></MainMap>
    </v-col>

    <DialogEntity></DialogEntity>
    <DialogResMap></DialogResMap>
    
  </v-row> 
</v-col>
</template>


<style>
  .dialogSearchGeo #map-container{
    position: absolute;
    height:unset !important;
    bottom:0px;
    top:0px;
    right:0px;
    left:0px;
  }

  .col-search{
    /* background: rgba(255, 255, 255, 0.9); */
    z-index:1;
    overflow-x:hidden;
  }

  .item-scoller{
    overflow-y: auto;
    width: 100%;
    display: block;
  }

</style>

<script>

// import axios from "axios"
import ListResMap from '@/components/map/ListResMap.vue'
import DialogEntity from '@/components/map/DialogEntity.vue'
import DialogResMap from '@/components/map/DialogResMap.vue'
import MainMap from '@/components/map/MainMap.vue'

// import core from '../../plugins/core.js'

export default {
  name: 'home',
  components: {
    ListResMap, DialogEntity, MainMap, DialogResMap
  },
  data: () => ({
    openPopupEntity: false,
    currentEntity: null,
    lastScrollOffset: window.screenTop,
  }),
  mounted: async function(){
    this.$store.dispatch('app/incStat', '/map')
    this.initData()
  },
  methods: {
    async initData(){
    },
    async fetchMap(){
      this.$root.$emit('fetchMap')
    },
    nl2br: function(str, is_xhtml){
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>';
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },
    
    
  },
  computed: {
  },
  watch: {
      '$route.path': { immediate: true, 
          async handler () {
            if(this.$store.state.map.results.companies.entities.length == 0
            ||this.$store.state.map.results.users.entities.length == 0)
              this.initData()
          }
      },
  }, 
}
</script>
